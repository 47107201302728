.imageV{
    height:100px;
    width:100px;
    border-radius: 180%;
    margin-left: 7%;
    margin-top: 10px;
    /* background-color: aqua; */
}


.ul{
    text-decoration: none;
    margin-left: 2%;
    width:20%;

}
.ulR{
    text-decoration: none;
    margin-left: -6.7%;
    width:20%;
}
.Hli{    
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid aqua;
    border-left: none;
    width:350%;
    text-align: center;
    display: inline-block;
    padding: 2%;
}
.li{
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid aqua;
    width:120%;
    text-align: center;
    display: inline-block;
    padding: 2%;

}
.uiwrapper{
    display: flex;
}
.Nli{
    display: block;
    color: red;
}
/* employee proile.............. */
.EmpImage{
    height:100px;
    width:100px;
    margin-left:8%;
    margin-top:2%
}

/* admin check approval */
.Approved{
    background-color: green;
    color: white;
    font-weight: bold;
    width:82px;
    border: 1px solid green;
    height: 23px;
}
.Approve{
    background-color: rgb(40, 4, 99);
    height: 23px;
    color: white;
    font-weight: bold;
    width:78px;
    border: 1px solid  rgb(40, 4, 99);
}
.Approval{
    height: 23px;
}
.DeleteButton{
    background-color: red;
    border:1px solid red;
    color: white;
    width:62px;
    height: 22px;
    font-weight: bold;
}

/* .....For Mobile....... */

#JobCardWrapper {
    /* display: flex; */
    /* flex-wrap: wrap; */
    padding: 10px;
    /* background-color: rgb(204, 103, 103); */
    border-radius: 10px;
    /* margin-left: 40px;     */
    width: 90%;

}

.JobCard {
    /* display: flex; */
    /* background-color: aqua;    */
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 8px;
    /* height: 250px; */
    width: 100%;
    border-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 2px 2px 2px 2px #999999;
    padding-bottom: 6px;
}
.LeftTable{
    margin-left: 7px;
    margin-top: 20px;
overflow:hidden;

    /* background-color: blue; */
    /* width:200%; */
}
.RightTable{
    margin-top: 20px;
    margin-left: 5px;
    width:60%;

overflow:hidden;

    /* background-color: aqua; */
}

.MobileimageView{
    height:30px;
    width:40px;
    margin-left: 1%;
    margin-top: 15px;
}
.span{
    font-weight: 500;
    font-size: small;
}
.Down{
    margin-left: 10px;
    overflow-wrap: break-word;


}
.Rejected{
    background-color: red;
    color: white;
    font-weight: bold;
    width:78px;
    border: 1px solid green;
    height: 23px;
}
.MobileApproved{
    background-color: green;
    color: white;
    font-weight: bold;
    width:52px;
    border: 1px solid green;
    height: 23px;
}
.MobileApprove{
    background-color: rgb(40, 4, 99);
    height: 23px;
    color: white;
    font-weight: bold;
    width:58px;
    border: 1px solid  rgb(40, 4, 99);
}