/* 

.carwrapper{
    background-color: rgb(219, 223, 220);
    display: flex;
    flex-wrap: wrap;
    width : 100% ;
}

.card{
    background-color: white;
    border : 1px solid rgb(194, 193, 193);
    margin: 1%;
    width : 31%;
     border-top: 4px solid rgb(55, 16, 92);

}
.AlltexWrapper{
    margin-left: 6%;
}
.descriptionAndApply{
    display: flex;  
    align-items: center;   
}
.description{        
    flex-wrap: wrap;
    width: 60%;
    word-wrap: break-word
}
.seeMore{
    color : rgb(104, 50, 190)
}
.button{
    border: 1px solid rgb(40, 4, 99);
    color : rgb(40, 4, 99);
    font-weight: 550; 
    font-size: small; 
    height: 30px;
    width:100px;
    margin-left: 25px;    
}
.text{
    font-weight: 550;
} */
/* .......................CardDetails............. */
/* .detailedcarwrapper{
    width : 50%;
    background-color: rgb(219, 223, 220);

}

.showLess{
    color:rgb(109, 52, 201);
}
.detailedcard{
    background-color: white;
    border : 1px solid rgb(194, 193, 193);
    margin: 1%;
     border-top: 4px solid rgb(55, 16, 92);
    width : 100%
}
.detaileddescription{
    display: inline;
} */

/* ..............UL......LI............. */

.arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    /* margin-top: 100px; */
    padding: 3px;
  }
  
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

/* .HeadingSort{
    display: flex;
    margin-left: 40px;
    background-color: rgb(67, 146, 146);
} */
/* .AllradioWrapper{
    background-color: brown;
    height:80px;
    margin-top: -20px;
    width:100px
} */
.AllHeadingSortWrapper{
    /* background-color: brown; */
    /* width:1000px; */
    height:80px;
    display: flex;
}
.FilterHeading{
    opacity: 0.6;
    /* background-color: antiquewhite; */
    width:200px;
    display: flex;
    height: 20px;
}
.JobSorting{
    margin-left: 50px

}
.ExpSorting{
    margin-left: 80px
}
.PackageSorting{
    margin-left: 85px

}
.RadioWrapper{
    /* background-color: rgb(0, 68, 255); */
     width: 150px;
     margin-left: -150px; 
    margin-top: 35px; 
}
.dropdownWrapper{
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.inputtypeLeft{
    width:50px;
    border-right: none;
    border-left: none;
}
.inputtypeRight{
    width:50px;
    border-right: none;
}
.dropdownleft{
    border:2px solid;
    border-right: none;

}
.dropdownright{
    border:2px solid;

}

.searchBoth{
    margin-left: 3%;
    margin-top: 15px;

    display: flex;
}
.p{
    font-weight: bold;
    margin-top: 20px;
    margin-left: 10%;
    color: #3b3b3b;
    /* opacity: 0.8; */
}
.inputboxsearch{
    display: inline-flex;
    height : 35px;
    margin-top: 13px;
    margin-left: 2%;
    width: 50%;
    border-radius:5px;
    border : 1.5px solid rgb(9, 247, 247);
}

.seeMore{
 color: blue;
    cursor:pointer;

}
.Uiwarpper{
    /* margin-top: 20px; */
    margin-left: -2%;
    /* background-color: bisque; */
}
.ul{
    display: flex;
    margin: 0px;
    margin-left: -.2%;
}
.li{
    display: inline-block;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    width : 8%;
    border : 1px solid rgb(9, 247, 247);
    text-align: center;
    overflow-wrap:break-word

}
.CompanyName{
    width:10%;
    overflow-wrap:break-word

}
.Source{
    width:6%;
    overflow-wrap:break-word


}
.liDescription{
    width : 17%;
    overflow-wrap:break-word;
    text-align: center;

}
.HliDescription{
    width : 17%;
    overflow-wrap:break-word;
    text-align:center ;
}
.JobType{
    width:6%;
    overflow-wrap:break-word

}
.date{
    width:6%;
    overflow-wrap:break-word

}
.qualification{
    width:6.5%;
    overflow-wrap:break-word

}
.Jtitle{
    width:8%;
    overflow-wrap:break-word

}
.Package{
    width:7%;
    overflow-wrap:break-word

}
.experiance{
    width:4%;
    overflow-wrap:break-word

}
.Location{
    width:7.5%;
    overflow-wrap:break-word

}
.Skills{
    width:12%;
    overflow-wrap:break-word

}
.Apply{
    width:85px
}
.Status{
    width:90px

}
.Applybutton{
    border: 1px solid rgb(40, 4, 99);
    background-color: rgb(40, 4, 99);
    display: flex;
    color: white;
    font-weight: bold;
    width:75px;
    padding-left: 15px;
    /* padding-right: 1px; */
    height: 25px;
    align-items: center;
    text-align: center;
    margin-left: 6%;
    cursor:pointer;

}

.Appliedbutton{
    border: 1px solid green;
    background-color:green;    
    color: white;
    font-weight: bold;
    width:79px;
    height: 25px;    
    /* margin-left: -5%; */
    cursor:pointer;

}
.Loader{
    width:75%;
    margin-left: -15%;
}
/* ..............job details............. */

.dUiwarpper{
    margin-top: 10px;
    display: flex;
}

.Hul{
    width : 40%;
}
.Hli{
    padding: 0.5%;
    width : 65%;
    border : 1px solid rgb(9, 247, 247);
    text-align: center;
    /* border-right: none; */
    display: inline-block;  

}
.Rul{
    width : 40%;
height: 180px;


}
.Rli{
    display: inline-block;
    margin-left: -55%;
    padding: 0.5%;
    width : 165%;
    border : 1px solid rgb(9, 247, 247);
border-left: none;

    text-align: center;
}
.DUIli{
    border-top: none;
    border : 1px solid rgb(9, 247, 247);
    width : 210.8%;


}
.Dli{
    display: inline-block;
}
.RDli{
    display: inline-block;
    width : 99.8%;

}

.showLess{
    color:rgb(109, 52, 201);
}
.JobtitleFilterWrapper-{
    margin-left: 20%;

}


.JobtitleFilter{
    margin-left: 1%;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid rgb(40, 4, 99);
    background-color: rgb(40, 4, 99);
    color:white;
    font-size: small;
    font-weight: bold;
    height: 30px;
    border-radius: 5px;
}



.MobJobtitleFilterWrapper{
    margin-left: 2%;
}
/* 
.MobJobtitleFilter{
    margin-left: 1%;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid rgb(40, 4, 99);
    background-color: rgb(40, 4, 99);
    color:white;
    font-size: small;
    font-weight: bold;
    height: 30px;
    border-radius: 5px;

} */


/* ......Cards.................cards Css... */

#JobCardWrapper {
    /* display: flex; */
    /* flex-wrap: wrap; */
    padding: 10px;
    /* background-color: rgb(204, 103, 103); */
    border-radius: 10px;
    /* margin-left: 40px;     */
    width:90%
}

.JobCard {
    /* display: flex; */
    /* background-color: aqua;    */
    margin-left: 8px;
    margin-top: 15px;
    margin-bottom: 15px;
    white-space:wrap;
    /* height: 250px; */
    width: 100%;
    border-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 2px 2px 2px 2px #999999;
    padding-bottom: 7px;
    padding-top: 6px;
}

.JobTitleDateWrapper{
    margin-left: 15px;
    display: flex;
    margin-top: -10px;
}
.jobTitle{
    font-weight: 500;
    font-size:medium;
    display: inline;
    text-decoration: underline;
    width:90%;
    overflow: hidden;
    text-wrap: nowrap;
}
.Date{
    font-weight: 500;
    font-size: x-small;
    width:25%;
}
.companyNameLocationWrapper{
    margin-left: 15px;
}
.logo{
    width:25px;
    height:20px;
    margin-top: -50px;

}

.companyName{
    margin-left: 5px;
    font-weight: 400;
    text-decoration: underline;  
    font-size: medium;
    width:100%
}

.jobLocation{
    font-weight: 500;
    font-size: small;
    margin-left: 5px;
    margin-top:4px;

}
.jobLocationImage{
    width:20px;
    height:20px;
    margin-top: 6.5px;
    margin-left: 15px;

}
.graduationImage{
    width:20px;
    height:20px;
    /* margin-left: 2px; */
    /* margin-top: 20px; */
}

.jobtypeAndDate{
    font-size: small;
    margin-left: 15px;
    font-weight: 500;
}
.qualificationAndExperiance{
    font-size: small;
    margin-left: 5px;
    /* font-weight: 500; */
}
.skillWrapper{
    margin-left: 15px;

}
.skillsHeading{
    font-weight: 600;
    font-size: small;

}
.skills{    
    font-weight: 400;
    font-size: small;

    
}
.jobDescriptionHeading{
    margin-left: 15px;
    font-weight: 600;
    margin-top: 5px;
}
.jobDescription{
    margin-top: -15px;
    margin-left: 15px;
    width:94%;
    font-size: small;
    font-weight: 400;
    /* background-color: aqua; */
}

.ApplyPackage {
    display: flex;
    margin-left: 15px;
    border: 1px solid rgb(40, 4, 99);
    background-color: rgb(241,248,234);
    width:90%;
    border-radius: 10px; 
    height: 50px;
    margin-top: 8px;
    /*
    padding-bottom: 15px;
     */
}
.salaryRange{
    font-weight: bold;
    margin-left: 30px;
    margin-top: 13px;
    font-size: large;
}
.ApplyMobile {
    border: 1px solid rgb(40, 4, 99);
    background-color: rgb(40, 4, 99);
    display: flex;
    color: white;
    font-weight: bold;
    width:70px;
    height: 28px;
    align-items: center;
    text-align: center;
    align-content: center;
    margin-left: 40%;
    margin-top: 12px;
    border-radius: 5px;
}
.MobileAppliedButton{
    border: 1px solid green;
    border-radius: 5px;
    background-color:green;  
    display: flex;
    color: white;
    font-weight: bold;
    width:80px;
    height: 28px;    
    align-items: center;
    text-align: center;
    align-content: center;
    margin-left: 38%;
    margin-top: 12px;
}



