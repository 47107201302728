
.searchBoth{
    margin-left: 4%;
    margin-top: 15px;

    display: flex;
}
.p{
    font-weight: bold;
    margin-top: 20px;
    margin-left: 5%;
    opacity: 0.8;


}
.inputboxsearch{
    display: inline-flex;
    height : 35px;
    margin-top: 13px;
    margin-left: 2%;
    width: 55%;
    border-radius:5px;
    border : 1.5px solid rgb(9, 247, 247);
}
.h3{
    color: blue;
    margin-left: 4%;
}
.ul {
    display: flex;
    margin: 0px;
    margin-left: -30px;
}

.li {
    display: inline-block;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    width:8%;
    border: 1px solid rgb(9, 247, 247);
    text-align: center;
}
.Name{
    width:12%;
    overflow-wrap: break-word

}
.phoneNumber{
    width: 9%;
overflow-wrap: break-word

}
.CompanyName{
    width:11%;
overflow-wrap: break-word

}

.CompanyAddress {
    width: 15%;
overflow-wrap: break-word

}
.Date {
    width: 6%
}
.CompanyWebsite{
    width: 13%;
overflow-wrap: break-word

}
.DeleteAction{
    width:10%
}
.DeleteButton {
    /* background-color: rgb(40, 4, 99); */
    background-color: red;
    border:1px solid red;
    color: white;
    width:62px;
    height: 22px;
    font-weight: bold;

}
.Approval{
    width:10%
}
.Message{
    width: 22%;
    overflow-wrap: break-word    
}
.Approved{
    background-color: green;
    color: white;
    font-weight: bold;
    width:82px;
    border: 1px solid green;
    height: 23px;
}
.Rejected{
    background-color: red;
    color: white;
    font-weight: bold;
    width:82px;
    border: 1px solid green;
    height: 23px;
    margin-top:3px;
}
.OnHold{
    background-color: blue;
    color: white;
    font-weight: bold;
    width:82px;
    border: 1px solid green;
    height: 23px;
    margin-top:3px;
}
.Approve{
    background-color: rgb(40, 4, 99);
    height: 23px;
    color: white;
    font-weight: bold;
    width:78px;
    border: 1px solid green;
}

/* .....For Mobile....... */

#JobCardWrapper {
    /* display: flex; */
    /* flex-wrap: wrap; */
    padding: 10px;
    /* background-color: rgb(204, 103, 103); */
    border-radius: 10px;
    /* margin-left: 40px;     */
    width: 90%;

}

.JobCard {
    /* display: flex; */
    /* background-color: aqua;    */
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 8px;
    /* height: 250px; */
    width: 100%;
    border-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 2px 2px 2px 2px #999999;
    padding-bottom: 6px;
}

.LeftTable{
    margin-left: 10px;
    margin-top: 20px;
    /* background-color: blue; */
}
.RightTable{
    margin-top: 20px;
    margin-left: 7px;
width:60%;
white-space:wrap;
/* overflow-wrap: break-word; */
overflow:hidden
    /* background-color: aqua; */
}
.span{
    font-weight: 500;
    font-size: small;
}
.Down{
    margin-left: 10px;
overflow-wrap: break-word



}