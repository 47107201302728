
.searchBoth {
    margin-left: 15%;
    display: flex;
}

.p {
    font-weight: bold;
    margin-top: 20px;
}
.GoBackButton{
    background-color: rgb(40, 4, 99);
    color: white;
    /* font-size:medium; */
    font-weight: bold;
    height: 40px;
    border:1px solid rgb(40, 4, 99);
    margin-left: 6%;
    margin-top: 30px;
    border-radius: 5px;
    cursor: pointer;
}


.inputboxsearch {
    display: inline-flex;
    height: 35px;
    margin-top: 10px;
    margin-left: 1%;
    width: 60%;
    border-radius: 5px;
    border: 1.5px solid rgb(11, 12, 12);
}

.AllUiWrapper{
    margin-left: -30px;
    margin-top: 2%;
}


.ul{
    display: flex;
    margin: 0px;

}

.li{
    display: block;
    border: 1px solid aqua;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    padding-right: 0.3%;
    padding-left: 0.3%;
    text-align: center;
}
.Nli{
    display: block;
    color:red
}

.name {
    width:15%;
    display: block;
    
}
.onclick{
    text-decoration: underline;
    color: blue;
    /* cursor: pointer; */
}
.namelink{
    display: block;
    cursor: pointer;
}
.age{
  width:  7%
}
.Qualification{
  width:  9%
}
.Experiance{
   width: 8.5%
}
.Skills{
    width:22%
}
.currentCTC{
   width: 7.5%
}
.ExpectedSalary{
   width: 7.5%
}
.checkProfile{
   width: 7%
}
.NoticePeriod{
   width: 7%
}
.Status{
    width: 100px

}
/* .ViewProfile{
    border: 1px solid rgb(40, 4, 99);
    background-color: rgb(40, 4, 99);
    color: white;
    font-weight: bold;
    height: 20px;
} */

/* .....For Mobile....... */

#JobCardWrapper {
    /* display: flex; */
    /* flex-wrap: wrap; */
    padding: 10px;
    /* background-color: rgb(204, 103, 103); */
    border-radius: 10px;
    /* margin-left: 40px;     */
    width: 90%;

}

.JobCard {
    /* display: flex; */
    /* background-color: aqua;    */
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 8px;
    /* height: 250px; */
    width: 100%;
    border-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 2px 2px 2px 2px #999999;
    padding-bottom: 6px;
}
.LeftTable{
    margin-left: 7px;
    margin-top: 20px;
    overflow:hidden;
text-wrap: nowrap;
    width:35%;
}
.MobileimageView{
    height:30px;
    width:40px;
    margin-left: 1%;
    margin-top: 15px;
}
.RightTable{
    margin-top: 20px;
    margin-left: 5px;
    width:60%;
overflow:hidden;
text-wrap: nowrap;
}
.span{
    font-weight: 500;
    font-size: small;
}
.Down{
    margin-left: 10px;
    overflow-wrap: break-word;


}