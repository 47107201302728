
.FooterWrapper{
    display: flex;
    /* background-color:rgb(0,30,90); */
    background-color: rgb(29, 1, 75);
    margin-top: 29%;


    width:100%;
    padding-bottom: 1%;
    padding-top: 1%;
    height: 100px;
    align-items: center;
    align-content: center;
    text-align: center;

}

.FooterItem{
    margin-left: 10%;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

@media(max-width:500px){
    .FooterItem{
        margin-left: 5%;
        font-weight: bold;
        color: white;
    }
}
