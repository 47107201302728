
.Loginpage{
    color:grey;
    margin-left: 10px;
}


.BothsignUpWrapper{
    margin-top: 6%;
    margin-left: 42%;
}

@media (max-width:850px){
    .BothsignUpWrapper{
        margin-top: 6%;
        margin-left: 34%;
    }
}
@media (max-width:500px){
    .BothsignUpWrapper{
        margin-top: 6%;
        margin-left: 20%;
    }
}


.OR {
    margin-left: 100px;
    margin-top: 10px;
    color: rgb(85, 85, 85);
    /* background-color: blue; */
}

.inputs {
    border: 1px solid rgb(175, 179, 188);
    /* background-color: aqua; */
    /* width: 35%; */
    width:220px;
    display: block;
    border-radius: 8px;
    height: 30px;
    margin-top: 1%;

}

.button {
    background-color: rgb(40, 4, 99);
    color: white;
    /* width: 35.5%; */
    width: 228px;

    cursor: pointer;
    font-size: medium;
    font-weight: 550;
    height: 35px;
    /* width: 10%; */
}



.signUpWrapper {
    display: flex;
    flex-wrap: nowrap;
    width: 230px;
    /* margin-left: 38%; */
    padding: 0.1%;
    border: 1px solid black;
    border-radius: 25px;
    margin-top: 2%;
    cursor: pointer;
    /* background-color: aqua; */
}

.signUpwrap {
    color: rgb(0, 0, 0);
    font-weight: 550;
    font-size: medium;
    margin-left: 3%;
    margin-top: 9px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    /* background-color: blue; */
}

.google {
    height: 25px;
    margin-top: 7.5px;
    /* width :10% */
}

.both {
    display: flex;
    width: 100%;
    height: 40px;
    margin-left: 4%;
    /* background-color: red; */
    display: flex;
    flex-wrap: nowrap;
}


.error {
    color: red;
    margin-left: 280px;
}

.hrwrapper {
    margin-left: 300px;
    width: 50%;
}

.line {
    width: 200px;
}

.topsideError {
    color: red;
    margin-left: 450px;
}

.notAccount {
    /* background-color: red; */
    width: 20%;
    display: flex;
    margin-left: 35.5%;
}

.topuperror {
    margin-left: 36%;
    color: red;
}

.Registerlink {
    text-decoration: none;
}

